export default {
  SM_ARIES_DOMAIN:
    process.env['NODE_ENV'] === 'development'
      ? 'https://ram-dev23.ose-dev39-vxbyr.cloud.marriott.com'
      : process.env['NGINX_URL'],
  SM_PROPERTY_RECORDS_ENDPOINT: '/aries-search/v1/propertyRecords.comp',
  SM_PROPERTY_RECORDS_VARIATION: '0.4',
  SM_PHOTOS: 'Photos',
  SM_LOCATION: 'Location',
  SM_MAX_OCCUPANCY: 'closedMaxOccupancyExceeded',
  SM_HOTEL_INFO: 'Hotel Information',
  SM_AMENITIES: 'Amenities',
  SM_PROPERTY_DETAILS: 'Property Details',
  SM_SCROLL_LIMIT: 205,
  SM_MOBILE_SCROLL_LIMIT: 290,

  SM_REVIEWS_LINK: '/hotels/hotel-reviews/',

  SM_ELEGANT_HOTELS: [
    'CUNMH',
    'CUNPC',
    'CUNPA',
    'LIRPH',
    'MBJGN',
    'ANURO',
    'PUJRS',
    'PUJRO',
    'PUJHI',
    'PUJRC',
    'PUJRB',
    'MBJRW',
    'MBJRB',
    'MBJRN',
    'MBJHN',
    'CUNHV',
    'CUNRC',
    'UVFRO',
    'UVFHI',
  ],
  UXL_RESOLVER_ENDPOINT:
    process.env['NODE_ENV'] === 'development'
      ? 'http://localhost:9090'
      : process.env['UXL_RESOLVER_ENDPOINT'] ??
        'https://ram-test23.ose-dev39-vxbyr.cloud.marriott.com/hybrid-component-resolver',
  UXL_ENDPOINT: process.env['UXL_ENDPOINT'] ?? 'https://gatewaydsaptst1.marriott.com/v1/graph/query',
  DEFAULT_API_ACCEPT_LANGUAGE: process.env['DEFAULT_API_ACCEPT_LANGUAGE'] ?? 'en-US',
  SHOP_UXL_CLIENT_NAME: process.env['SHOP_UXL_CLIENT_NAME'] ?? 'phoenix_shop',
  SELL_OPTIONS_WITH_STANDARD_RATES: ['sell-strategy', 'direct'],
  HOTEL_GALLERY_TITLE_OPERATION_SIGNATURE: '024d10b6e81ba3abf12cca8fe41782dc79d68456459c719208905d10b449d25f',
  HOTEL_GALLERY_TITLE_PROPERTY_OPERATION_SIGNATURE: '2e6687ae3fdd7209c9c21f85f56df9a151303a2b17b98423c6108f41a9f66da5',
  HQV_CONVERT_CURRENCY_QUERY_SIGNATURE: '94abd99041296a17d6c03c7a3b49dcd627a0c1822aae1828ae11a1c6e9dfd5d3',
  SM_SECTIONS: [
    'hqv-photo-section',
    'hqv-location-section',
    'hqv-hotel-info-section',
    'hqv-amenities-section',
    'hqv-property-details-section',
  ],
  enableAverageNightlyRate: true,
  //this is to specify allowed domains for domain specific features like mandatory fee
  ALLOWED_DOMAINS: ['en-US'],
};

/* below constants requires subdirectory prefix  */
export const NAVIGATION_URL_CONSTANTS = {
  SM_VIEW_HOTEL_WEBSITE_LINK: '/hotels/travel/',
  SM_VIEW_AVAILABILITY_LINK: '?isRateCalendar=true&propertyCode=',
  SM_VIEW_RATES_URL: '?propertyCode=',
  SM_VIEW_RATES_ADF_URL: '?isRateCalendar=true&propertyCode=',
  //changed as per release branch
  SM_VIEW_RATES_LINK: '?propertyCode=',
};

export enum hardCodedAllLocale {
  jp = 'ja-JP',
  ko = 'ko-KR',
  en_US = 'en-US',
}

import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop'; //  '@mi-apps/ui-library/src/styles-3.0';
import { baseVariables } from '@marriott/mi-ui-library';

export const Styled = styled.div`
  ${rtl`
  .custom-info-list{
    pointer-events:none;
    // margin-right: ${toRem(-10)} !important;
    font-size:${toRem(12)};
    line-height:${toRem(16)};
    font-family: ${theme.fonts['fontFamilySwiss']};
    .hqv-mandatoryfee-tooltip {
      color: ${baseVariables?.color?.neutral40};
    }
  }

  .primary-title,.secondary-title {
    max-height: ${toRem(144)} !important;
    text-overflow: ellipsis;
    overflow-y: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-wrap: pretty;
  }

  @media ${baseVariables.mediaQuery.md} {
    .primary-title,.secondary-title {
      -webkit-line-clamp: 3;
      text-wrap: pretty;
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    .primary-title,.secondary-title {
      max-height: ${toRem(108)} !important;
      -webkit-line-clamp: 2;
      text-wrap: pretty;
    }
  }
  
  .remove-info-icon:{
    :after{
     content:none !important;
    }
  }
  .includes-fee{
    font-size:${toRem(12)};
    line-height: 1rem;
    font-family: Swiss-721;
    color: ${theme.color['dark-gray-14']};
    margin-bottom: ${toRem(-10)};
    @media only ${theme.mediaQuery.allMobiles} {
      margin-bottom: 0;
    }
  }
  .m-link-tertiary-button-external{
    line-height: ${toRem(12)} !important;
  }
  .m-link-tertiary-button-external:after{
    padding-left: 0;
  }
  .m-link-action{
    line-height: ${toRem(12)} !important;
  }
  .m-link-action:after{
    padding-left:0;
  }
.custom-info-list:after{
  pointer-events:all;
  height:${toRem(13.33)};
  width:${toRem(13.33)};
  padding:0;
}
.custom-info-list:hover{
  background-color:transparent !important ;
  cursor: pointer !important ;
}
.custom-position{
    top:15%;
    margin: 0 auto;
  }
.tooltip-popup-wrapper{
    max-height:${toRem(513)};
    min-height:${toRem(290)};
  }
  .popup-close-button{
    background:none !important;
    font-size:xx-large;
  }
.custom-header-font h4{
  font-size: ${toRem(30)};
  font-weight: ${theme.fonts['fontWeightMedium']};
  letter-spacing: 0;
  line-height: ${toRem(32)};
}
.custom-header-font{
  padding-top: ${toRem(32)};
  padding-bottom:${toRem(32)};
}
.custom-heights{
    height:auto !important;
    max-height:${toRem(400)} !important;
    overflow-y:auto !important;
  }
.tooltip-main-wrapper{
    height:${toRem(200)};
    font-size:${toRem(16)};
    font-family: 'Swiss721-BT-Medium';
    letter-spacing: 0;
    line-height: ${toRem(20)};
  }
.tooltip-main-content{
    // margin:${toRem(15)} 0 0 0;
    font-size:${toRem(12)};
    letter-spacing: 0;
    line-height: ${toRem(18)};
    font-weight: ${theme.fonts['fontWeightRegular']};
  }
.custom-list{
    // padding-left:${toRem(10)};
    width:100%;
    font-family: 'Swiss721-BT-Regular';
    padding-right: ${toRem(30)};
  }
  .custom-ul-props{
    column-count:2;
    margin-inline-start: ${toRem(-10)} !important;
    margin-inline-end: ${toRem(-14)} !important;
  }
  .row-reverse{
    flex-direction:row-reverse !important;
    height:${toRem(81)};
  }
  .view-rates-button-container {
          margin-left: ${toRem(24)};
          width: ${toRem(161)};
          .view-rates-button {
            width: 100%;
            display: flex;
            align-items: center;
            height:${toRem(40)};
            padding: ${toRem(9)} ${toRem(40)};
            &:focus {
              outline: none;
            }
          }
        }
  .view-rates-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${toRem(9)} ${toRem(40)};
    height:${toRem(40)};
  }
  .view-rates-button:focus {
    outline: 0;
  }
  .rate-unavailable-text {
    margin-bottom: 0;
  }
  @media only ${theme.mediaQuery.allTablets} {
    .view-rates-button-container {
      width: ${toRem(137)};
      margin-left: ${toRem(24)};
      margin-right: ${toRem(16)};
    }
    .custom-header-font h4{
      font-size: ${toRem(22)};
      font-weight: ${theme.fonts['fontWeightMedium']};
      letter-spacing: 0;
      line-height: ${toRem(28)};
    }
    .custom-ul-props{
      column-count:1;
    }
    .popup-close-button{
      background-color:${theme.color['gallery']};
      font-size:x-large;
      height: ${toRem(32)};
      width: ${toRem(32)};
    }
    .custom-list{
        padding-left:${toRem(10)};
        width:100%;
        font-family: 'Swiss721-BT-Regular';
     }
  .popup-width {
          width: ${toRem(584)};
    }
  .tooltip-popup-wrapper{
      max-height:${toRem(640)};
      min-height:${toRem(293)};
    }
    .view-rates-button-container {
            margin-left: ${toRem(24)};
            margin-right: 0;
          }
  }
   @media only ${theme.mediaQuery.mobileOnly} {
    .popup-width {
      width: inherit;
    }
        .view-rates-button-container {
      width: ${toRem(137)};
      margin-left: ${toRem(8)};
      margin-right: ${toRem(16)};
      .view-rates-button{
        height: ${toRem(40)};
        display: flex;
        align-items: center;
      }
    }
    .popup-close-button{
          background-color:${theme.color['gallery']};
          font-size:large;
        }
    .row-reverse{
          height:${toRem(74)};
        }
    .custom-header-font h4{
        font-size: ${toRem(18)};
        font-weight: ${theme.fonts['fontWeightMedium']};
        letter-spacing: 0;
        line-height: ${toRem(20)};
      }
    .tooltip-popup-wrapper{
        max-height:${toRem(760)};
        min-height:${toRem(310)};
        margin-top:0;
      }
    .custom-position{
      top:unset;
      margin: 0 auto;
    }
    .custom-ul-props{
      column-count:1;
    }
    .custom-list{
          padding-left:${toRem(10)};
          width:100%;
          font-family: 'Swiss721-BT-Regular';
      }
    custom-info-list:after{
      pointer-events:all;
      height:${toRem(13.33)};
      width:${toRem(13.33)};
      margin-top:${toRem(15)} !important;
    }
  }
.title-main-container {

  background: ${theme.color['white']};
  max-width: ${toRem(1440)};
  margin: 0 auto;
  border-bottom: 1px solid  ${theme.color['light-gray-6']};

  .underlined-label{
    text-decoration: underline;
    font-size: ${toRem(13)};
  }

  @media only ${theme.mediaQuery.largeTablet} {
    padding: 0;
  }

  @media only ${theme.mediaQuery.mobileOnly} {
    padding: 0;
    border-bottom: none ;
    overflow: hidden;
    width: 100%;
  }
.text-descriptor{
  color: ${baseVariables.color.neutral40}
}
.title-container {
  padding: ${toRem(56)} ${toRem(158)} 0 ${toRem(158)};
  width: 100%;
  display: inline-flex;
  &__cn-box {
   display: inline-block;
   margin-left: ${toRem(12)};
   text-decoration: none;
   color: ${theme.color['black']};
   .t-subtitle-m{
     display: inline-block;
   }
  }
   .mi-icon {
    display: inline-block;
    margin-right: ${toRem(8)};
    color: ${theme.color['black']};
    &.icon-location{
      height: 17px;
    }
    &.icon-phone{
      margin-right: 4px;
      height: 18px;
    }
   }
  &__contactNumber {
   font-weight: ${theme.fonts['fontWeightRegular']};
  }
  &__subTitle {
   display: inline-block;
   margin-left: ${toRem(12)};
  }
   &.reviews-absent {
    margin-left: 0;
  }
  &__rating {
   color: ${theme.color['black']};
   text-decoration: none;
  }
  &__category {
   align-self: center;
  }
  .distance-container {
    margin-right: ${toRem(12)};
  }
  &__category-box {
   display: inline-block;
   text-decoration: none;
   color: ${theme.color['black']};
  }
  &__third-row {
   display: block;
   height: 16px;
  }
  &__viewSite {
   border: 0;
   background-color: #fff;
   color: ${theme.color['fiery-Orange']};
  }
  &__hotel-name {
   margin-bottom: ${toRem(9)};
  }
  &-text {
   margin-left: ${toRem(5)};
   color: ${theme.color['black']};
  }
  &-list-miles {
   display: inline-block;
  }
  &__title {
   width: 80%;
  }
  &__adress {
   margin-bottom: ${toRem(9)};
   display: flex;
   flex-direction:row;
   .disabled-section{
     display: none;
   }
  }
  &__adress-title {
   font-weight: ${theme.fonts['fontWeightRegular']};
  }
  &__subTitle-mobile {
    display: none;
  }
  &__title,
  &__left-container,
  &__right-container {
   display: inline-block;
  }
  &__right-container {
   align-items: center;
   font-size: ${toRem(15)};
   font-family: cursive;
   display: inline-flex;
   flex-direction: row;
   justify-content: flex-start;
   .border-class{
     border-right: ${toRem(1.5)} solid ${theme.color['close-silver']};
     height: 100%;
     margin: 0 ${toRem(36)};
   }
    .brand-logo-MR::before {
      font-size: ${toRem(68)};
    }
    .brand-logo-RCR::before {
      font-size: ${toRem(24)};
    }
    .brand-logo-RZ::before {
      font-size: ${toRem(72)};
    }
    .brand-logo-EB::before {
      font-size: ${toRem(28)};
    }
    .brand-logo-JW::before {
      font-size: ${toRem(58)};
    }
    .brand-logo-AK::before {
      font-size: ${toRem(62)};
    }
    .brand-logo-BR::before {
      font-size: ${toRem(76)};
    }
    .brand-logo-DE::before {
      font-size: ${toRem(80)};
    }
    .brand-logo-MC::before {
      font-size: ${toRem(78)};
    }
    .brand-logo-ER::before {
      font-size: ${toRem(66)};
    }
    .brand-logo-MV::before {
      font-size: ${toRem(78)};
    }
    .brand-logo-GE::before {
      font-size: ${toRem(70)};
    }
    .brand-logo-AR::before {
      font-size: ${toRem(80)};
    }
    .brand-logo-CY::before {
      font-size: ${toRem(22)};
    }
    .brand-logo-RI::before {
      font-size: ${toRem(26)};
    }
    .brand-logo-SH::before {
      font-size: ${toRem(52)};
    }
    .brand-logo-FI::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-TS::before {
      font-size: ${toRem(50)};
    }
    .brand-logo-PR::before {
      font-size: ${toRem(62)};
    }
    .brand-logo-OX::before {
      font-size: ${toRem(48)};
    }
    .brand-logo-XR::before {
      font-size: ${toRem(70)};
    }
    .brand-logo-LC::before {
      font-size: ${toRem(70)};
    }
    .brand-logo-WH::before {
      font-size: ${toRem(76)};
    }
    .brand-logo-WI::before {
      font-size: ${toRem(32)};
    }
    .brand-logo-SI::before {
      font-size: ${toRem(66)};
    }
    .brand-logo-MD::before {
      font-size: ${toRem(22)};
    }
    .brand-logo-TX::before {
      font-size: ${toRem(28)};
    }
    .brand-logo-DS::before {
      font-size: ${toRem(14.56)};
    }
    .brand-logo-AL::before {
      font-size: ${toRem(62)};
    }
    .brand-logo-FP::before {
      font-size: ${toRem(84)};
    }
    .brand-logo-EL::before {
      font-size: ${toRem(80)};
    }
    .brand-logo-BG::before {
      font-size: ${toRem(32)};
    }
    .brand-logo-HV::before {
      font-size: ${toRem(62)};
    }
    .brand-logo-MI::before {
      font-size: ${toRem(58)};
    }
  }
  .hotel-logo {
    height: ${toRem(100)};
    width: ${toRem(100)};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.brand-ar {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ar/global-property-shared/en_us/logo/assets/ar-ar-logo-l-28972.png');
    }
    &.brand-al {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/al/global-property-shared/en_us/logo/assets/al_logo_L.png');
    }
    &.brand-ak {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ak/global-property-shared/en_us/logo/assets/ak_logo_L.png');
    }
    &.brand-bg {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/bg/global-property-shared/en_us/logo/assets/bg_logo_L.png');
    }
    &.brand-cy {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/cy/global-property-shared/en_us/logo/assets/cy_logo_L.png');
    }
    &.brand-de {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/de/global-property-shared/en_us/logo/assets/de-delta-hotels-logo-41030.png');
    }
    &.brand-ds {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ds/global-property-shared/en_us/logo/assets/ds_logo_L.png');
    }
    &.brand-eb {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/eb/global-property-shared/en_us/logo/assets/eb_logo_L.png');
    }
    &.brand-el {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/el/global-property-shared/en_us/logo/assets/el_logo_L.png');
    }
    &.brand-fi {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/fi/global-property-shared/en_us/logo/assets/fi_logo_L.png');
    }
    &.brand-fp {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/fp/global-property-shared/en_us/logo/assets/fp_logo_L.png');
    }
    &.brand-ge {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ge/global-property-shared/en_us/logo/assets/ge_logo_L.png');
    }
    &.brand-jw {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/jw/global-property-shared/en_us/logo/assets/jw_logo_L.png');
    }
    &.brand-md {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/md/global-property-shared/en_us/logo/assets/md_logo_L.png');
    }
    &.brand-er {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/er/global-property-shared/en_us/logo/assets/er_logo_L.png');
    }
    &.brand-mc {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png');
    }
    &.brand-mv {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/mv/global-property-shared/en_us/logo/assets/mv_logo_L.png');
    }
    &.brand-ox {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ox/global-property-shared/en_us/logo/assets/ox_logo_L.png');
    }
    &.brand-pr {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/pr/global-property-shared/en_us/logo/assets/pr_logo_L.png');
    }
    &.brand-br {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/br/global-property-shared/en_us/logo/assets/br_logo_L.png');
    }
    &.brand-ri {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ri/global-property-shared/en_us/logo/assets/ri_logo_L.png');
    }
    &.brand-si {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/si/global-property-shared/en_us/logo/assets/si_logo_L.png');
    }
    &.brand-sh {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/sh/global-property-shared/en_us/logo/assets/sh_logo_L.png');
    }
    &.brand-xr {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/xr/global-property-shared/en_us/logo/assets/xr-xr-logo-l-33456.png');
    }
    &.brand-lc {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/lc/global-property-shared/en_us/logo/assets/lc_logo_L.png');
    }
    &.brand-rz {
      background-image: url('https://s7d2.scene7.com/is/image/ritzcarlton/ritz-carlton-primary-black?$LargeViewport25pct$');
    }
    &.brand-ts {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/ts/global-property-shared/en_us/logo/assets/ts_logo_L.png');
    }
    &.brand-tx {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/tx/global-property-shared/en_us/logo/assets/tx_logo_L.png');
    }
    &.brand-wh {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/wh/global-property-shared/en_us/logo/assets/wh_logo_L.png');
    }
    &.brand-wi {
      background-image: url('https://cache.marriott.com/content/dam/marriott-digital/wi/global-property-shared/en_us/logo/assets/wi_logo_L.png');
    }
  }
  .marriott-header-primary-logo {
    height: ${toRem(60)};
  }
}

.disableLinks {
    pointer-events: none;
  }

.circle-rating {
 display: inline-block;
 margin-right: ${toRem(10)};
 &__rating-text {
  padding-right: ${toRem(4)};
 }
&__circle-inner-rating {
 width: ${toRem(10)};
 height: ${toRem(10)};
 border: ${toRem(1.5)} solid ${theme.color['red-damask']};
 border-radius: 100%;
 box-sizing: border-box;
 position: relative;
 display: inline-block;
 margin-left: ${toRem(3)};
}
.full-star {
  background: linear-gradient(to right, ${theme.color['red-damask']} 100%, transparent 0);
}
.star-1 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 10%, transparent 0);
}
.star-2 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 20%, transparent 0);
}
.star-3 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 30%, transparent 0);
}
.star-4 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 40%, transparent 0);
}
.star-5 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 50%, transparent 0);
}
.star-6 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 60%, transparent 0);
}
.star-7 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 70%, transparent 0);
}
.star-8 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 80%, transparent 0);
}
.star-9 {
  background: linear-gradient(to right, ${theme.color['red-damask']} 90%, transparent 0);
}
&__circle-inner-rating::before {
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 content: "";
 display: block;
 position: absolute;
 border-radius: 100%;
 background: transparent;
}
}
.round-circle {
 width: ${toRem(3)};
 height: ${toRem(3)};
 border-radius: 50%;
 border: ${toRem(1)} solid ${theme.color['black']};
 background-color: ${theme.color['black']};
 display: inline-block;
 margin-left: ${toRem(12)};
 margin-bottom: ${toRem(3)};
 align-self: center;
 max-height: ${toRem(3)};
 max-width: ${toRem(3)};
}
.view-rates {
 text-align: right;
 padding-top: ${toRem(30)};
 padding-bottom:  ${toRem(10)};
 margin-bottom: ${toRem(-1)};

  &__view-rates:hover{
    font-weight: ${theme.fonts['fontWeightRegular']};
  }

}
.menu-section-rates {
  padding-top: ${toRem(8)};
  border-top: ${toRem(1)} solid ${theme.color['light-gray-6']};
  border-bottom: ${toRem(1)} solid ${theme.color['light-gray-6']};
  margin-left: ${toRem(-158)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.title-container-menu {
  padding: ${toRem(24)} ${toRem(158)}  ${toRem(24)} ${toRem(158)};
  &_item {
    color: ${theme.color['black']};
    margin-right: ${toRem(40)};
    padding-bottom:  ${toRem(24)};
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      text-decoration: none;
      color: black;
    }
  }
  &_item:hover{
    font-weight: ${theme.fonts['fontWeightRegular']};
  }
  .menu-selected {
    border-bottom: ${toRem(3)} solid #1c1c1c;
   }
}
.position-sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${toRem(1440)};
  margin: auto;
  width: 100%;
  background: white;
  z-index: 24602;
  padding-left: ${toRem(158)};
}
.hotel-rate {
 color: ${theme.color['black']};
}
.hotel-rate-points {
 color: ${theme.color['black']};
}
.opening-soon-text {
  margin-right: ${toRem(158)};
}
.view-rates-link {
  display: inline-block;
  border-radius: ${toRem(27)};
  margin-right: ${toRem(158)};
  text-align: center;
  .calendar-icon {
    display : none;
  }
}
.view-rates-link:hover{
  font-weight: ${theme.fonts['fontWeightRegular']};
}
.view-rates-sticky {
  margin: auto;
  position: fixed;
  max-width: ${toRem(1440)};
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  z-index: 24602;
  .view-rates-link, .opening-soon-text {
    margin-right: ${toRem(158)};
  }
}
.date-unavailable {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: ${toRem(-14)};
  .reservation-icon:before, .arrow-icon:before {
    color: ${theme.color['fiery-Orange']};
  }
  .flexible-dates-text {
    font-family: ${theme.fonts['fontFamilySwiss']};
    color: ${theme.color['fiery-Orange']};
    font-size: ${toRem(14)};
    line-height: ${toRem(18)};
    &:hover {
      text-decoration: underline;
    }
  }
  .flexible-date-button {
    font-family: ${theme.fonts['fontFamilySwiss']};
    font-weight: ${theme.fonts['fontWeightRegular']};
    color: ${theme.color['fiery-Orange']};
    font-size: ${toRem(16)};
    line-height: ${toRem(23)};
    margin-right: ${toRem(10)};
    &:hover {
      text-decoration: underline;
    }
  }
}
.show-imformation-text {
  text-decoration: none;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: ${toRem(24)};
}
@media only ${theme.mediaQuery.mobileOnly} {
  .hqv-rate-container {
    .price-value {
      display: inline !important;
      .striked-mandatory-fee {
        .eeo-price-value{
          left: ${toRem(16)};
        }
      }
      .eeo-price-value{
        left: 0;
      }
      .currency-label {
        margin-left: 0;
      }
    }
    .hqv-modal-points-styling {
      .price-section {
        display: inline;
      }
      .points-value {
        display:inline;
      }
      .points-currency-label {
        display:inline;
      }
    }
  }
}
.strike-through {
  text-decoration: line-through;
  display: flex;
  align-items: center;
  color: ${theme.color['dark-gray-13']};
  .eeo-price-value{
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
}
.show-information-text-points{
  text-decoration: none;
  display:inline-block;
}
.points-container{
  display:inline-grid;
}
.points-price-container{
  display:grid;
  margin-right: ${toRem(24)};

  .show-imformation-text {
    margin-right: 0;
  }
}
.soldout-text {
 margin-right: ${toRem(6)};
 margin-top: ${toRem(16)} !important;
}
.call-to-book {
  margin-right: ${toRem(30)} !important;
}
.custom-info-list{
    pointer-events:none;
    // margin-right: ${toRem(-10)} !important;
    font-size:${toRem(12)};
    line-height:${toRem(16)};
    font-family: ${theme.fonts['fontFamilySwiss']};
}
.custom-info-list:after{
  pointer-events:all;
  height:${toRem(13.33)};
  width:${toRem(13.33)};
  padding:0;
}
.custom-info-list:hover{
  background-color:transparent !important ;
  cursor: pointer !important ;
}
.tooltip-popup-wrapper{
    max-height:${toRem(513)};
    min-height:${toRem(290)};
  }
.custom-header-font h4{
  font-size: ${toRem(30)};
  font-weight: ${theme.fonts['fontWeightMedium']};
  letter-spacing: 0;
  line-height: ${toRem(32)};
}
.custom-header-font{
  padding-top: ${toRem(32)};
  padding-bottom:${toRem(32)};
}
.custom-heights{
    height:auto !important;
    max-height:${toRem(400)} !important;
    overflow:unset !important;
    overflow-y:auto !important;
  }
.tooltip-main-wrapper{
    height:${toRem(200)};
    font-size:${toRem(16)};
    font-family: 'Swiss721-BT-Medium';
    letter-spacing: 0;
    line-height: ${toRem(20)};
  }
.tooltip-main-content{
    // margin:${toRem(15)} 0 0 0;
    font-size:${toRem(12)};
    letter-spacing: 0;
    line-height: ${toRem(18)};
    font-weight: ${theme.fonts['fontWeightRegular']};

  }
.custom-list{
    padding-left:${toRem(10)};
    width:100%;
    font-family: 'Swiss721-BT-Regular';
  }
  .row-reverse{
    flex-direction:row-reverse !important;
    height:${toRem(81)};
  }
@media only ${theme.mediaQuery.largeTablet} {
  .view-rates{
    width: 100%;
    margin-left: 0;
    .view-rates-link, .opening-soon-text {
      margin-right: ${toRem(33)};
    }
  }
  .adults-only-msg{
    .view-rates-link, .opening-soon-text{
      margin-right: 0;
    }
  }
  .view-rates-sticky{
    .view-rates-link, .opening-soon-text {
      margin-right: ${toRem(33)};
    }
  }
  .title-container-menu {
    padding: ${toRem(16)} ${toRem(32)} ${toRem(12)} ${toRem(32)};
    border-top: ${toRem(1)} ;
    &_item {
      padding-bottom:  ${toRem(12)};
    }
  }
  .menu-section-rates {
    padding-top: ${toRem(8)};
  }
  .title-container{
     padding : ${toRem(40)} ${toRem(33)} ${toRem(10)};
    &__right-container{
      display: none;
    }
    &__hotel-name{
      margin-bottom: ${toRem(8)};
    }
    &__cn-box {
      margin-left: 0;
    }
    &__title {
      width: 100%;
    }
    &__third-row {
      margin-top: 0;
      margin-bottom: ${toRem(8)};
      .round-circle{
        margin-left: ${toRem(12)};
      }
    }
    &__adress{
      display:flex;
      margin-bottom: ${toRem(2)};
      flex-direction:column;
    }
    &__address-box {
      display: flex;
      margin-bottom: ${toRem(0)};
      .round-circle {
        display: none;
      }
    }
   .mi-icon {
      margin-right: ${toRem(10)};
    }
  }

@media only ${theme.mediaQuery.smallerTablet}{
  .view-rates{
    margin-top: 0;
    margin-left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    padding: ${toRem(10)} ${toRem(33)};
    padding-left: 0;
    border-top: ${toRem(1)} solid ${theme.color['light-gray-6']};
    margin-bottom: ${toRem(-1)};
    .view-rates-link {
      margin-right: ${toRem(20)}; !important;
    }
  }

  .adults-only-msg {
    .view-rates-link, .opening-soon-text{
      margin-right: 0;
    }
  }

  .title-container__adress{
    display:flex;
    flex-direction:column;
    margin-bottom: ${toRem(2)};
  }
  .title-container__address-box {
    display: block;
    margin-bottom: ${toRem(0)};
    max-width:74%;
    .round-circle {
      display: none;
    }
  }
}
@media only ${theme.mediaQuery.mobileOnly} {
  .view-rates{
    .view-rates-link, .opening-soon-text {
      margin-right: ${toRem(16)};
    }
  }

  .view-rates-button-container {
    width: ${toRem(137)};
    margin-left: ${toRem(8)};
    margin-right: ${toRem(16)};
  }
  .view-rates-button{
    padding: ${toRem(8)} ${toRem(24)};
    height: ${toRem(40)};
    padding: ${toRem(8)} ${toRem(24)};
    display: flex;
    align-items: center;
  }
  .circle-rating {
    margin-left: -3px;

  }
  .view-rates-link-mob-only{
    flex : none;
  }
  .view-rates-link {
    z-index: 9;
    margin-right: ${toRem(16)};
    .calendar-icon {
      display: inline;
      color: ${theme.color['white']};
      margin-right: ${toRem(8)};
    }
    .icon-nav---book:before {
      color: ${theme.color['white']};
    }
  }
  .hotel-rate-unavailable {
    display: block;
    margin-bottom: ${toRem(8)};
  }
  .opening-soon-text {
    padding: ${toRem(10)} ${toRem(20)} ${toRem(10)} 0;
    margin: 0 !important;
  }
  .date-unavailable {
    top: 0;
  }
  .soldout-text {
    margin-right: ${toRem(16)};
  }
  .call-to-book {
    margin-right: ${toRem(40)} !important;
  }
 .view-rates {
  border-top: ${toRem(1)} solid ${theme.color['light-gray-6']};
  padding: ${toRem(8)} 0;
  margin-top: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  margin-left: 0;
 }
  .title-container-menu {
    padding: ${toRem(12)} 0 ${toRem(16)} ${toRem(16)};
    border-bottom: ${toRem(1)};
    overflow: scroll hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    position: relative;
    z-index: 24602;
    background-color: white;
    &_item {
      padding-bottom:  ${toRem(12)};
    }
  }
  .position-sticky-menu{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 24602;
  }

  @media only ${theme.mediaQuery.allMobiles} {
    .view-rates-button-container {
      width: ${toRem(137)};
      margin-left: ${toRem(8)};
      margin-right: ${toRem(16)};
    }
    .title-container-menu {
      z-index: 49;
    }
    .position-sticky-menu{
      z-index: 49;
    }
  }
 .title-container{
  display: inline-block;
  padding: ${toRem(18)} ${toRem(16)} ${toRem(10)};
  overflow-y: scroll;
  &__adress{
    display:flex;
    flex-direction:column;
    margin-bottom: ${toRem(13)};
  }
  &__hotel-name{
  margin-bottom: ${toRem(7)};
  }
  &__address-box {
   display: block;
   margin-bottom: ${toRem(0)};
   align-items: baseline;
  }
  &__category-box {
    display: block;
    .title-container__category{
      width: auto;
      margin: 0;
    }
   .round-circle {
    display: none;
   }
  }
  &__contactNumber {
   display: inline-block;
   margin: ${toRem(5)} 0 0 0;
  }
  &__rating {
   width: 52%;
  }
  &__third-row{
    .round-circle{
      margin-right: ${toRem(12)};
    }
  }
  &__subTitle {
   display: inline-block;
   margin: 0;
  }
  &__viewSite {
   display: none;
  }
  }
}
@media only ${theme.mediaQuery.minDesktop}{
  .title-container{
    .title-container__adress{
      flex-wrap: wrap;
      .title-container__address-box{
        white-space: nowrap;
        display: flex;
        align-items: baseline;
        max-width:74%;
        .t-subtitle-m{
          white-space: break-spaces;
        }
        .round-circle{
          margin-right: ${toRem(12)};
        }
      }
      .title-container__cn-box{
        white-space: nowrap;
        margin-left: 0;
      }
      }
    }
  }
}
}
.hqv-modal {
  .edit-rates-label{
    display: none;
  }
  .edit-rates{
    margin-top: ${toRem(10)};
    margin-left: ${toRem(16)};
    text-align: left;
  }
  .edit-guest-label{
    display: none;
  }
  .max-view-rates{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .max-occupancy{
    margin-left: ${toRem(24)};
    @media only ${theme.mediaQuery.allMobiles} {
      margin-left: 0;
    }
    margin-top: ${toRem(10)};
    flex-direction: column;
    align-items: flex-start;
  }
  .title-main-container {
    border: 0;
  }
  .view-rates{
    width: 100%;
    margin-left: 0;
    .view-rates-link, .opening-soon-text {
      margin-right: ${toRem(20)};
      margin-left: ${toRem(24)};
    }
  }
  .adult-only-msg-section {
    width: 100%;
    .adult-only-property {
      text-align: left;
      padding-left: ${toRem(24)};
      .adults-only-msg{
        display: flex;
        flex-direction: column;
        .view-rates-link, .opening-soon-text{
          margin-right: 0;
        }
      }
    }
  }
  .view-rates-sticky{
    .view-rates-link, .opening-soon-text {
      margin-right: ${toRem(20)};
      margin-left: ${toRem(24)};
    }
  }
  .hqv-price-container {
    display: flex;
    align-items: center;
    @media only ${theme.mediaQuery.allMobiles} {
      max-width: 100%;
      .price-sub-section {
        overflow: hidden;
        .points-section {
          max-width: calc(100% - ${toRem(16)});
          .price-section {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            width: 100%;
          }
        }
      }
    }
    .hqv-price-component {
      cursor: default;
    }
    .price-sub-section {
      .hqv-price-component {
        .currency-label {
          padding-bottom: 0 !important;
        }
        .taxes-currency-label {
          padding-bottom: ${toRem(3)} !important;
        }
      }
    }
    .price-section {
      display: flex;
      justify-content: end;
      .points-currency-label {
        padding-top: ${toRem(6)};
        font-size: ${toRem(16)};
        margin-left: ${toRem(5)};
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .currency-section {
      display: flex;
      justify-content: flex-end;
      .price-section-color{
        justify-content: flex-end;
        align-items: baseline;
      }
      .price-value {
        line-height: inherit;
        font-size: ${toRem(22)};
        font-weight: ${theme.fonts['fontWeightMedium']};
        color: ${theme.color['black']};
        @media ${baseVariables.mediaQuery.md} {
          line-height: ${toRem(33)};
        }
      }
      .currency-label {
        font-size: ${toRem(16)};
        color: ${theme.color['black']};
        font-weight: normal;
        padding-bottom: 0;
        padding-top: ${toRem(10)};
      }
    }
  }
  .title-container-menu {
    display: none;
  }
  .menu-section-rates {
    padding-top: ${toRem(8)};
    position: absolute;
    bottom: 0;
    background: white;
    z-index: 3;
    right: 0;
    border-color: transparent;
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    background-color: ${theme.color['background']};
    margin: 0;
    @media only ${theme.mediaQuery.allMobiles} {
      border-radius: unset;
    }
  }
  .title-container{
    padding:0 !important;
    &__right-container{
      display: none;
    }
    &__hotel-name{
      margin-bottom: ${toRem(8)};
      font-weight: ${theme.fonts['fontWeightRegular']};
    }
    &__cn-box {
      margin-left: 0;
    }
    &__title {
      width: 100%;
      margin-left: ${toRem(24)};
      @media only ${theme.mediaQuery.allMobiles}{
        margin-left:0 !important;
      }
    }
    &__third-row {
      margin-top: 0;
      margin-bottom: ${toRem(8)};
      .round-circle{
        margin-left: ${toRem(12)};
      }
    }
    &__adress{
      display:flex;
      margin-bottom: ${toRem(2)};
      flex-direction:column;
      @media only ${theme.mediaQuery.minDesktop} {
        flex-direction:row;
      }
    }
    &__address-box {
      display: flex;
      margin-bottom: ${toRem(0)};
      max-width:75%;
      .round-circle {
        @media only ${theme.mediaQuery.allMobiles} {
          display: none;
        }
        @media only ${theme.mediaQuery.allTablets} {
          display: none;
        }
        @media only ${theme.mediaQuery.minDesktop} {
          margin-right: ${toRem(10)};
          display: inline-block;
          &.address-line-circle {
            margin-left: ${toRem(14)};
            margin-bottom: ${toRem(21)};
            margin-right: ${toRem(15)};
          }
        }
      }
    }
   .mi-icon {
      margin-right: ${toRem(10)};
    }
  }
  @media only ${theme.mediaQuery.allMobiles} {
    .title-container {
      margin: 0  ${toRem(16)} ${toRem(25)} ${toRem(16)};
      .tooltip-container:hover::after {
        transform: translateX(-${toRem(76)});
    }
      .distance-container {
        margin-right: 0;
        .round-circle {
          display: none;
        }
      }
    }
  }
  .title-container__title {
    padding-right: ${toRem(24)};
  }
}`}
`;

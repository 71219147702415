import React, { useContext, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import { Styled } from './index.styles';
import { serverData } from '../HotelOverview';
import { phoenixShopPropertyInfoCall, phoenixShopHQVPropertyInfoCall } from '../../HotelGalleryTitle/HQVQueries';
import { isServer } from '@marriott/mi-apollo-client-utils';
import { usePageContext } from '../../../../context/PageContext';
import { overviewMockData } from '../mockdata/overview';
import { getDTTHeaders, logger } from '../../../../utils';
import { useCheckBreakpoint } from '../../../../hooks';
import { useHQVContext } from '../../../../context/HQVContext';
import { eventUtil } from '@marriott/mi-ui-library-shop';

const { log } = logger({})('HotelGalleryInfoWrapper');

const Overview: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { jsonContent }: any = useContext(serverData);
  const { hqvState } = useHQVContext();
  const { requestId, pageState, currentLocale, isDTT } = usePageContext();
  const isViewportL = useCheckBreakpoint('viewportL');
  // const propertyId = pageState.authorMode ? 'NYCES' : resolvedUrl?.split('propertyId=')[1]?.split('&')[0] ?? 'NYCES';
  // const reqId = pageState.authorMode ? `${Date.now()}` : requestId ?? `${Date.now()}`;
  // const propertyId = useMemo(() => {
  //   return pageState.authorMode
  //     ? 'NYCES'
  //     : resolvedUrl?.split('propertyId=')[1]?.split('&')[0] ?? DEFAULT_FALLBACK_PROPERTY_ID;
  // }, [resolvedUrl, pageState]);
  const propertyId = hqvState.propertyIDvalue;
  const reqId = useMemo(() => {
    return pageState.authorMode ? `${Date.now()}` : requestId ?? `${Date.now()}`;
  }, [pageState, requestId]);

  const skipQuery = useMemo(() => {
    return !!pageState.authorMode;
  }, [pageState]);

  const queryToBeCalled = isDTT ? phoenixShopHQVPropertyInfoCall : phoenixShopPropertyInfoCall;

  const {
    // loading: overviewDataLoading,
    error: overviewDataError,
    data: overviewData,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(queryToBeCalled, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: propertyId,
      filter: 'PHONE',
      descriptionsFilter: ['LOCATION'],
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'x-request-id': reqId,
        'accept-language': currentLocale,
        ...getDTTHeaders(isDTT),
      },
    },
    skip: skipQuery,
    onCompleted: data => {
      log.debug('phoenixShopHotelOverview complete: ', data);
    },
  });

  // Dispatching event to notify the findHotels page that the loader can be hidden
  // flagLocation is set solely for debugging
  useEffect(() => {
    if (selectedDescription) {
      eventUtil.dispatch(`HQVComponentCallCompleted`, { flagLocation: 'overviewFlag' });
    }
  }, []);

  const propertyOverviewData = useMemo(() => {
    return pageState.authorMode ? overviewMockData.textContentData : overviewData;
  }, [pageState, overviewData]);

  if (overviewDataError) {
    return null;
  }

  let selectedDescription = '';
  propertyOverviewData?.property?.basicInformation?.descriptions.forEach(
    (description: { type: { code: string }; localizedText: { translatedText: string } }) => {
      if (description?.type?.code === 'location') {
        selectedDescription = description?.localizedText?.translatedText;
      }
    }
  );

  return (
    <Styled>
      {selectedDescription && (
        <div
          className={clsx(
            'px-3',
            'color-scheme1',
            'hotel-overview-container',
            `${hqvState?.modalOpenState ? 'hqv-modal' : ''}`
          )}
        >
          <div className="overview-title-container">
            {jsonContent && (
              <h2 className={clsx(isViewportL ? 't-title-s' : 't-subtitle-xl', 'overview-title')}>
                {jsonContent.overviewLabel}
              </h2>
            )}
            <p className={clsx(isViewportL ? 't-font-m' : 't-font-s', 'overview-description')}>{selectedDescription}</p>
          </div>
        </div>
      )}
    </Styled>
  );
};

export default Overview;

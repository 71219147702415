/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useEffect } from 'react';
import { StyledSortByFilterContainerDiv } from './index.styles';
import { renderedData } from '../../organisms/searchResults';
import { SearchFilterContext, SearchResultContext, SearchResultAlertContext } from '../../index.context';
import clsx from 'clsx';
import {
  SearchFacetsConstants,
  SearchFacetsList,
  SearchSortFields,
  SearchSortFieldsDirection,
  SearchSortFieldForCITY,
  SearchSortFieldForBRAND,
  SearchSortFieldForDISTANCE,
  RECOMMENDED_SORT,
  RECOMMENDED_OPTION,
} from '../../index.constant';
import { SearchActions } from '../../../store/store.action';
// import { ApplyFiltersComponent } from '../../atoms/applyButton';
import { useWindowSize } from '../../../../../../hooks';
import { BREAKPOINT_DESKTOP_1023, BREAKPOINT_TABLET } from '../../../lib/application.constants';
import { useSessionUpdateContext } from '../../../../../../context';
import { usePageContext } from '../../../../../../context/PageContext/index';
import { hardCodedAllLocale } from '../../index.constant';
import { enableBoostBrands, enableHotelList } from '../../../lib/helper';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortByFilterComponent: React.FC<{
  isMobileState: boolean;
  jsonContents?: any;
  segmentId?: string;
  isLatLongAvailable?: boolean;
}> = ({ isMobileState, jsonContents, segmentId, isLatLongAvailable }) => {
  const clickTrackingLoc = 'Phoenix Search Results';

  const { searchQueryState, setSearchQuery } = useContext(SearchFilterContext);
  const { searchResultAlerts } = useContext(SearchResultAlertContext);
  const { searchResult } = useContext(SearchResultContext);
  const { currentLocale, headersData } = usePageContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { jsonContent, setFeatureSelected, isStateOrCountry, isFlexibleDateSearch, isMapViewEnabled }: any =
    useContext(renderedData);
  const { interactionsSelected, setInteractionsSelected } = useSessionUpdateContext();
  const sortOptionsList =
    jsonContents ??
    (isFlexibleDateSearch
      ? segmentId !== RECOMMENDED_SORT || isLatLongAvailable
        ? jsonContent?.sortByOptionsList?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) =>
              key.sortByOptionCode !== SearchFacetsList.price &&
              key.sortByOptionCode !== SearchFacetsList.points &&
              key.sortByOptionCode !== RECOMMENDED_OPTION
          )
        : jsonContent?.sortByOptionsList?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) =>
              key.sortByOptionCode !== SearchFacetsList.price && key.sortByOptionCode !== SearchFacetsList.points
          )
      : segmentId !== RECOMMENDED_SORT || isLatLongAvailable
      ? jsonContent?.sortByOptionsList?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (key: any) => key.sortByOptionCode !== RECOMMENDED_OPTION
        )
      : jsonContent?.sortByOptionsList);

  // to determine sort options list for different scenarios
  let sortOptions =
    enableHotelList() === true
      ? searchQueryState.pointsEnable
        ? sortOptionsList?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) => key.sortByOptionCode !== SearchFacetsList.price && key.sortByOptionCode !== 'DISTANCE'
          )
        : sortOptionsList?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) => key.sortByOptionCode !== SearchFacetsList.points && key.sortByOptionCode !== 'DISTANCE'
          )
      : searchQueryState.pointsEnable
      ? isStateOrCountry
        ? sortOptionsList?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) =>
              key.sortByOptionCode !== SearchFacetsList.price &&
              key.sortByOptionCode !== 'DISTANCE' &&
              key.sortByOptionCode !== 'PROPERTY_NAME'
          )
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sortOptionsList?.filter(
            (key: any) => key.sortByOptionCode !== SearchFacetsList.price && key.sortByOptionCode !== 'PROPERTY_NAME'
          )
      : isStateOrCountry &&
        (searchResult?.searchCenter?.latitude === null ||
          searchResult?.searchCenter === null ||
          !searchResult?.searchCenter)
      ? sortOptionsList?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (key: any) =>
            key.sortByOptionCode !== SearchFacetsList.points &&
            key.sortByOptionCode !== 'DISTANCE' &&
            key.sortByOptionCode !== 'PROPERTY_NAME'
        )
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortOptionsList?.filter(
          (key: any) => key.sortByOptionCode !== SearchFacetsList.points && key.sortByOptionCode !== 'PROPERTY_NAME'
        );

  const [isSortAccordianOpen, setIsSortAccordianOpen] = useState(false);

  //To check the sort fields based on the searchCenter and latitude values
  const currSelectedSortOptions =
    (searchResult?.searchCenter?.latitude === null ||
      searchResult?.searchCenter === null ||
      !searchResult?.searchCenter) &&
    (!searchQueryState?.search?.latitude || searchQueryState?.search?.latitude === null) &&
    searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field === 'CITY'
      ? searchQueryState[SearchFacetsConstants.sort]?.fields[1]
      : searchQueryState[SearchFacetsConstants.sort]?.fields[0];

  const [currSortByOption, setCurrSortByOption] = useState<string | undefined>(currSelectedSortOptions?.field);
  const { width } = useWindowSize();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function expandSortByAccordian(event: any): void {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setIsSortAccordianOpen(!isSortAccordianOpen);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function closeSortByAccordion(event: any): void {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setIsSortAccordianOpen(false);
    }
  }
  //Logic for removing sort for too many results
  if (searchResultAlerts.type && searchResultAlerts.type === 'warning' && isStateOrCountry) {
    sortOptions = sortOptions?.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (key: any) =>
        key.sortByOptionCode !== 'DISTANCE' &&
        key.sortByOptionCode !== SearchFacetsList.price &&
        key.sortByOptionCode !== SearchFacetsList.points
    );
  }

  useEffect(() => {
    //To check the sort fields based on the searchCenter and latitude values
    (!searchQueryState?.search?.latitude || searchQueryState?.search?.latitude === null) &&
    (searchResult?.searchCenter?.latitude === null ||
      searchResult?.searchCenter === null ||
      !searchResult?.searchCenter) &&
    searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field === 'CITY'
      ? setCurrSortByOption(searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field)
      : setCurrSortByOption(searchQueryState[SearchFacetsConstants.sort]?.fields[0]?.field);

    if (window && window?.dataLayer) {
      window.dataLayer['search_list_setting_sort_order'] =
        (!searchQueryState?.search?.latitude || searchQueryState?.search?.latitude === null) &&
        (searchResult?.searchCenter?.latitude === null ||
          searchResult?.searchCenter === null ||
          !searchResult?.searchCenter) &&
        searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field === 'CITY'
          ? searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field?.toLowerCase()
          : searchQueryState[SearchFacetsConstants.sort]?.fields[0]?.field?.toLowerCase();
    }
  }, [searchQueryState, searchResult]);
  const applySortOptionsToSearchQuery = (val: string): void => {
    setSearchQuery({
      type: SearchActions.updateOtherSearchQueryParam,
      payload: {
        [SearchFacetsConstants.offset]: 0,
        [SearchFacetsConstants.goToNextPage]: 0,
        [SearchFacetsConstants.sort]: {
          fields: fetchSortFields(val),
        },
      },
    });
  };

  const fetchSortFields = (val: string) => {
    if (val === SearchSortFields.city) {
      return SearchSortFieldForCITY?.map(fieldVal => {
        return {
          field: fieldVal,
          direction: SearchSortFieldsDirection.asc,
        };
      });
    } else if (val === SearchSortFields.distance) {
      return SearchSortFieldForDISTANCE?.map(fieldVal => {
        return {
          field: fieldVal,
          direction: SearchSortFieldsDirection.asc,
        };
      });
    } else if (val === SearchSortFields.brand) {
      return SearchSortFieldForBRAND?.map(fieldVal => {
        return {
          field: fieldVal,
          direction: SearchSortFieldsDirection.asc,
        };
      });
    } else {
      return [
        {
          field: val,
          direction:
            SearchSortFields.guest_rating === val ||
            SearchSortFields.no_of_reviews === val ||
            val === RECOMMENDED_OPTION
              ? SearchSortFieldsDirection.desc
              : SearchSortFieldsDirection.asc,
        },
      ];
    }
  };

  // add few extra classes for de domain on basis of this flag, in order to achive some design changes
  const addDeClassOnSortBy = isMapViewEnabled && currentLocale === hardCodedAllLocale.de;
  const addDeClassOnSortByList = !isMapViewEnabled && currentLocale === hardCodedAllLocale.de;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function sortOptionSelected(event: any, sortOption: string): void {
    setFeatureSelected(true);
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setCurrSortByOption(sortOption);
      setIsSortAccordianOpen(false);
      applySortOptionsToSearchQuery(sortOption);
    }
    if (enableBoostBrands() === true) {
      const params = new URLSearchParams(window.location.search);
      params?.delete('marriottBrands');
      params?.delete('showMore');
      window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${params}`));
    }
    setInteractionsSelected({ ...interactionsSelected, sortBy: true });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeSortByOptions = sortOptions?.filter((key: any) => key.sortByOptionCode === currSortByOption)[0];

  return (
    <StyledSortByFilterContainerDiv
      className={clsx(
        addDeClassOnSortBy ? 'sort-container-de' : '',
        addDeClassOnSortByList ? 'sort-list-container-de' : ''
      )}
    >
      <div
        className={clsx(
          width && width > BREAKPOINT_DESKTOP_1023
            ? `t-subtitle-l`
            : width && width > BREAKPOINT_TABLET
            ? `t-subtitle-l`
            : `t-label-alt-xs`,
          'sort-by-section',
          addDeClassOnSortBy ? 'sort-by-section-de' : '',
          activeSortByOptions?.sortByOptionTitle === 'Anzahl der Bewertungen' && addDeClassOnSortByList
            ? 'sort-by-list-section-de'
            : ''
        )}
      >
        <div className="sort-by-label">{jsonContent?.sortByLabel}:</div>
        <div
          className={clsx('sort-by-options', isMapViewEnabled ? '' : 'sort-by-list')}
          onClick={(e): void => expandSortByAccordian(e)}
        >
          <div
            className="current-state custom_click_track"
            tabIndex={0}
            role="button"
            aria-label={`Sort by options: ${currSortByOption} dropdown`}
            aria-expanded={isSortAccordianOpen}
            {...{ custom_click_track_value: `${clickTrackingLoc}| Sort-by options dropdown |internal` }}
            onKeyDown={(e): void => expandSortByAccordian(e)}
            data-testid="sort-by-options"
          >
            <div className="current-sort-option">{activeSortByOptions?.sortByOptionTitle}</div>
            <div className="arrow-container">
              <span className="icon-arrow-down"></span>
            </div>
          </div>
          {isSortAccordianOpen ? (
            <>
              <div
                className={clsx(
                  'color-scheme1',
                  'expanded-container',
                  'hotel-category',
                  isMapViewEnabled ? 'map-expanded-container' : ''
                )}
              >
                {isMobileState ? (
                  <div className="t-label-alt-xs sort-options-mobile-title">
                    <h5 className="mb-0 header-heading">{jsonContent.sortByLabel}</h5>
                    <button
                      className="color-scheme3 t-font-s popup-close custom_click_track"
                      tabIndex={0}
                      onClick={(e): void => closeSortByAccordion(e)}
                      onKeyDown={(e): void => closeSortByAccordion(e)}
                      {...{ custom_click_track_value: `${clickTrackingLoc}| Close Button |internal` }}
                      aria-label="Close pop up"
                    >
                      <div className="icon-clear"></div>
                    </button>
                  </div>
                ) : (
                  <div className="t-label-xs sort-options-title">{jsonContent.sortByLabel}</div>
                )}
                <div className="sort-options-item-container">
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {sortOptions?.map((item: any, idx: number) => (
                    <div
                      className={`sort-option custom_click_track `}
                      key={idx}
                      role="radio"
                      aria-checked={currSortByOption === item?.sortByOptionCode}
                      tabIndex={0}
                      onBlur={(): void => {
                        if (idx === sortOptions.length - 1) {
                          setIsSortAccordianOpen(false);
                        }
                      }}
                      onClick={(e): void => sortOptionSelected(e, item.sortByOptionCode)}
                      onKeyDown={(e): void => sortOptionSelected(e, item.sortByOptionCode)}
                      {...{
                        custom_click_track_value: `${clickTrackingLoc}| Sort-by option: ${item?.sortByOptionTitle} |internal`,
                      }}
                    >
                      <div
                        className={
                          'sort-option-radio ' + (currSortByOption === item?.sortByOptionCode ? 'active ' : '')
                        }
                      ></div>
                      <div className="t-font-s sort-option-label">{item.sortByOptionTitle}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={(window.screen.width < 767 ? 'color-scheme2' : '') + ' expanded-container-parent'}></div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </StyledSortByFilterContainerDiv>
  );
};
